@import '~react-mde/lib/styles/css/react-mde-all.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~nprogress/nprogress.css';
@import '~react-big-calendar/lib/sass/styles';

@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
  src: local('Pretendard Variable'),
    url('/fonts/pretendard/variable/woff2/PretendardVariable.woff2') format('woff2-variations');
}

* {
  font-family: 'Pretendard Variable';
}


main {
  margin-inline-start: 0 !important;
}

html,
body,
#__next {
  height: 100%;
}

html,
body {
  &::-webkit-scrollbar {
    width: '8px';
  }
  &::-webkit-scrollbar-track {
    width: '6px';
  }
  &::-webkit-scrollbar-thumb {
    background: #171923;
    border-radius: '24px';
  }
}

.react-mde {
  font-family: 'Pretendard-Regular';
  color: #fff;
  background-color: #000;
  border: 1px solid #121212;

  .mde-header {
    color: #fff;
    background-color: #000;
  }

  svg {
    color: #fff;
  }

  .mde-tabs button {
    padding: 8px;
  }
}

.mde-text {
  color: #fff;
  background-color: #000;
}

.react-datepicker-wrapper input {
  background-color: inherit;
  color: #fff;
  padding: 10px 16px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 0.375rem;
  width: 100%;
}

.hashtag,
.instagram-link {
  cursor: pointer;
  /*
   * DOM 제어가 일어나는 구간이라서 chakra ui의 badge css 카피해옴.
   * DOM 제어에서도 chakra ui 스타일을 쓸 수 있다면 그걸 이용하자.
  */
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-transform: uppercase;
  font-size: 1rem;
  border-radius: 0.125rem;
  font-weight: 700;
  background: rgba(226, 232, 240, 0.16);
  color: #e2e8f0;
}

.screen-reader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#nprogress .bar {
  background: #e06354;
}

.rbc-day-bg + .rbc-day-bg,
.rbc-off-range-bg,
.rbc-events-container,
.rbc-today {
  background-color: transparent;
}

.rbc-month-row {
  min-height: 150px;
  height: auto;
}
@media only screen and (max-width: 1024px) {
  .rbc-toolbar {
    display: flex;
    flex-direction: column;
  }
}

.rounded-circle {
  border-radius: 50%;
}

.MarkdownText {
  word-break: keep-all;

  p {
    margin: 16px 0;
  }

  h1 {
    font-size: 150%;
  }
  h2 {
    font-size: 120%;
  }

  h3 {
    font-size: 110%;
  }

  h4 {
    font-size: 105%;
  }

  ul, ol {
    padding-left: 16px;
    margin-bottom: 24px;

    li {
      margin: 8px 0;
    }
  }

}
